<template>
  <select :value="modelValue" class="bg-transparent" @change="e => $emit('update:modelValue', e.target.value)">
    <option v-if="placeholder" value="">
      {{ placeholder }}
    </option>
    <option v-for="(name, val) in options" :key="val" :value="val" :selected="modelValue === val">
      {{ name }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    options: { type: Object, required: true },
    modelValue: { type: [String, Number, Object, Boolean], default: null },
    placeholder: { type: String, default: '' },
  },

  emits: ['update:modelValue'],
}
</script>
