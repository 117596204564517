<template>
  <div class="inline-flex flex-col">
    <label v-if="label || $slots.label" id="label" :for="id" class="align-start label mb-1.5 flex-1 text-sm font-semibold">
      {{ label }}
      <slot name="label" />
    </label>
    <div class="flex">
      <div
        class="relative flex w-full items-center rounded-md border bg-white px-4 py-3 text-sm shadow-custom ring-offset-0 focus-within:border-primary focus-within:ring-1 focus-within:ring-primary"
        :class="[wrapperClass, error ? 'border-red-500 text-red-900' : 'border-gray-200', { 'opacity-50': disabled }]"
      >
        <div v-if="$slots.prepend" class="pr-3">
          <slot name="prepend" />
        </div>
        <slot name="input" v-bind="{ id }">
          <BaseSelect
            v-if="type === 'select'"
            :id="id"
            ref="input"
            :model-value="formattedValue"
            :placeholder="placeholder"
            :options="options"
            :class="inputClass"
            class="w-full focus:outline-none"
            :disabled="disabled"
            v-bind="inpubBind"
            @update:model-value="v => input(v)"
          />
          <textarea
            v-else-if="type === 'textarea'"
            :id="id"
            ref="input"
            :rows="rows"
            :value="formattedValue"
            :placeholder="placeholder"
            :class="inputClass"
            class="w-full appearance-none focus:outline-none"
            :disabled="disabled"
            v-bind="inpubBind"
            @input="input($event.target.value)"
          />
          <input
            v-else
            :id="id"
            ref="input"
            :type="type"
            :value="formattedValue"
            :placeholder="placeholder"
            :class="inputClass"
            class="w-full appearance-none focus:outline-none"
            :disabled="disabled"
            v-bind="inpubBind"
            @input="input($event.target.value)"
          >
        </slot>
        <div v-if="$slots.append" class="pl-3">
          <slot name="append" />
        </div>
      </div>
      <div v-if="!noErrorIcon" class="exclamation-circle pointer-events-none ml-1.5 flex w-5 items-center">
        <ExclamationCircleIcon v-if="error" class="h-5 w-5 text-red-500" aria-hidden="true" />
      </div>
    </div>
    <div v-if="error" class="mt-3">
      <div class="text-sm text-red-500">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import omit from 'lodash/omit'
import ExclamationCircleIcon from '~/components/icons/ExclamationCircleIcon'
import BaseSelect from '~/components/ui/BaseSelect'

export default {
  components: { BaseSelect, ExclamationCircleIcon },

  props: {
    id: { type: String, default: () => uuidv4() },
    modelValue: { type: [String, Number, Object, Boolean], default: null },
    label: { type: String, default: null },
    error: { type: String, default: null },
    type: { type: String, default: 'text' },
    placeholder: { type: String, default: null },
    inputClass: { type: String, default: '' },
    wrapperClass: { type: String, default: '' },
    disabled: Boolean,
    noErrorIcon: Boolean,
    options: { type: Object, default: null },
    rows: { type: Number, default: 3 },
    coersion: { type: String, default: null },
  },

  emits: ['update:modelValue'],

  computed: {
    inpubBind () {
      return omit(this.$attrs, ['class'])
    },

    formattedValue () {
      if (this.coersion === 'datetime') {
        const date = dayjs(this.modelValue)
        return date.isValid() ? date.format('YYYY-MM-DDTHH:mm') : this.modelValue
      }

      // Workaround for select null value
      if (this.type === 'select' && this.value === null) {
        return 'null'
      }

      return this.modelValue
    },
  },

  methods: {
    input (val) {
      if (this.coersion === 'number') {
        val = Number(val)
        if (isNaN(val)) { val = 0 }
      }
      if (this.coersion === 'datetime') {
        const date = dayjs(val, 'YYYY-MM-DDTHH:mm')
        val = date.isValid() ? date.format('YYYY-MM-DDTHH:mm:ss[Z]') : val
      }

      // Workaround for select null, bool values
      if (this.type === 'select') {
        if (val === 'null') { val = null }
        if (val === 'false') { val = false }
        if (val === 'true') { val = true }
      }

      this.$emit('update:modelValue', val)
    },
  },
}
</script>
